import React, { ReactNode } from "react";
import { Box, BoxProps, Heading, Stack, HStack } from "@chakra-ui/react";

import SideNav from "../side-nav/Sidenav";

const HeadNav = (props: BoxProps) => (
  <Box display="flex" style={{ zIndex: 100 }} {...props} />
);

interface Props {
  withHeadNav?: boolean;
  withSideNav?: boolean;
  children: ReactNode;
}

const Layout = ({
  withHeadNav = false,
  withSideNav = false,
  children,
}: Props) => {
  return (
    <Stack backgroundColor="#E2E8F0">
      {withHeadNav && (
        <HeadNav backgroundColor="red">
          <Heading>Rental Manager</Heading>
        </HeadNav>
      )}
      <HStack>
        {withSideNav && <SideNav />}
        <Box maxW={600} margin="auto" backgroundColor="white">
          {children}
        </Box>
      </HStack>
    </Stack>
  );
};

export default Layout;
