import React from "react";
import * as Realm from "realm-web";

import { APP_ID } from "../constants";

export const app = new Realm.App(APP_ID);
export const RealmAppContext = React.createContext(app);

// Get a valid Realm user access token to authenticate requests
export async function getValidAccessToken() {
  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user
    await app.logIn(
      Realm.Credentials.emailPassword("andrew.chen923@gmail.com", "jp4912ed")
    );
  } else {
    // The logged in user's access token might be stale,
    // Refreshing custom data also refreshes the access token
    await app.currentUser.refreshCustomData();
  }
  // Get a valid access token for the current user
  return app.currentUser!.accessToken;
}

export async function loginEmailPassword(email: string, password: string) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  // Authenticate the user
  return app
    .logIn(credentials)
    .then((user) => user)
    .catch((err) => {
      throw err;
    });
}
