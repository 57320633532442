import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Applications from "./applications";
import Home from "./home";
import People from "./people";

const AppRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Home />
      </Route>
      <Route path={`${path}/applications`}>
        <Applications />
      </Route>
      <Route path={`${path}/people`}>
        <People />
      </Route>
      <Route path={`${path}/showings`}>
        <h1>Showings</h1>
      </Route>
    </Switch>
  );
};

export default AppRouter;
