import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";

import { PartialApplication } from "../../../types/applications";

interface Props {
  applications: PartialApplication[];
}

const ApplicationsTable = ({ applications }: Props) => {
  // const [filteredApplications, setFilteredApplications] = useState(
  //   applications
  // );
  const [filteredApplications] = useState(applications);

  // const onSearch = (search: Record<string, any>) => {
  //   let nextData;
  //   if (search) {
  //     const searchProps = Object.keys(search);

  //     nextData = applications.filter(
  //       (application) =>
  //         !searchProps.some((key) => {
  //           const currValue: string = search[key];
  //           if (!currValue) return false;
  //           switch (key) {
  //             case "name":
  //               const name = `${application.firstName} ${application.lastName}`.toLowerCase();
  //               return name.indexOf(currValue.toLowerCase()) === -1;
  //             default:
  //               return false;
  //           }
  //         })
  //     );
  //   } else {
  //     nextData = applications;
  //   }
  //   setFilteredApplications(nextData);
  // };

  return (
    <Table variant="simple">
      <TableCaption>
        Applications that we have registered in the system
      </TableCaption>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Phone</Th>
          <Th>Email</Th>
          <Th>Application Date</Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredApplications.map((application) => (
          <Tr>
            <Td>{`${application.firstName} ${application.lastName}`}</Td>
            <Td>{application.phoneNumber}</Td>
            <Td>{application.email}</Td>
            <Td>{application.applicationDate}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ApplicationsTable;
