// state type
export interface AuthFormState {
  email: string;
  password: string;
  confirmPassword: string;
  error: string;
  loading: boolean;
}

export interface BaseAuthFormAction {
  type: string;
}

export interface AuthFormFieldAction extends BaseAuthFormAction {
  field: keyof AuthFormState;
  value: string;
}

export interface AuthFormErrorAction extends BaseAuthFormAction {
  error: string;
}

export type AuthFormAction =
  | BaseAuthFormAction
  | AuthFormFieldAction
  | AuthFormErrorAction;

export enum AuthFormType {
  REGISTER,
  LOGIN,
}
