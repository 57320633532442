import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const ErrorPage = () => {
  return (
    <Box className="bg-red-100 p-10 rounded-md">
      <Heading marginBottom={15}>Oops... Page not found!</Heading>
      <Text>
        This seems to be a url that does not exist. Please try again with a
        different url.
      </Text>
    </Box>
  );
};

export default ErrorPage;
