import React from "react";
import { useQuery } from "@apollo/client";

import ApplicationsTable from "../../../components/applications/applications-table";
import { GET_APPLICATIONS } from "../../../services/graphql/operations";
import { PartialApplication } from "../../../types/applications";

const Applications = () => {
  const { loading, error, data } = useQuery<{
    applications: PartialApplication[];
  }>(GET_APPLICATIONS);
  if (loading) {
    return <p>loading data...</p>;
  } else if (error) {
    return <p>error occurred: {JSON.stringify(error)}</p>;
  }
  return (
    <div>
      <h1>Applications</h1>
      <ApplicationsTable applications={data?.applications || []} />
    </div>
  );
};

export default Applications;
