import { gql } from "@apollo/client";
/**
 * This file lists all of the queries that we'll need to query against Realm
 *
 * The format of the queries will be as follows:
 *
 * <VERB>_<NOUN(S)>
 *
 * for example: GET_APARTMENTS, POST_TENANT, etc.
 */

export const GET_APARTMENTS = gql`
  {
    apartments {
      _id
      name
      address {
        street
        city
        state
        zip
      }
    }
  }
`;

export const GET_PEOPLE = gql`
  {
    people {
      firstName
      lastName
      contact {
        email
        mobile
      }
    }
  }
`;

export const GET_APPLICATIONS = gql`
  {
    applications {
      _id
      firstName
      lastName
      email
      phoneNumber
      applicationDate
    }
  }
`;
