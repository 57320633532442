import React, { FormEvent } from "react";
import {
  Button,
  Heading,
  FormControl,
  FormLabel,
  Link,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useHistory, Link as NavLink } from "react-router-dom";

import {
  AuthFormAction,
  AuthFormState,
  AuthFormFieldAction,
  AuthFormType,
  AuthFormErrorAction,
} from "../../pages/auth/types";
import { loginEmailPassword } from "../../services/realm";

const initState: AuthFormState = {
  email: "",
  password: "",
  confirmPassword: "",
  error: "",
  loading: false,
};

function authReducer(
  state: AuthFormState,
  action: AuthFormAction
): AuthFormState {
  switch (action.type) {
    case "field": {
      const { field, value } = action as AuthFormFieldAction;
      return { ...state, [field]: value };
    }
    case "submit": {
      return { ...state, loading: true };
    }
    case "error": {
      const { error } = action as AuthFormErrorAction;
      return { ...state, password: "", error, loading: false };
    }
    default:
      return state;
  }
}

interface Props {
  authFormType: AuthFormType;
}

const AuthForm = ({ authFormType }: Props) => {
  const [state, dispatch] = React.useReducer(authReducer, initState);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const history = useHistory();
  const isInvalid =
    state.email === "" ||
    state.password === "" ||
    (authFormType === AuthFormType.REGISTER && state.confirmPassword === "");

  React.useEffect(() => {
    if (state.loading) {
      const login = async () => {
        try {
          await loginEmailPassword(state.email, state.password);
          history.push("/app");
        } catch (error) {
          dispatch({ type: "error", error: `Error occurred: ${error}` });
          return;
        }
      };
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch({ type: "submit" });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack maxW={600} margin="auto" spacing={5} marginTop="2rem">
        <Heading alignSelf="center">
          {authFormType === AuthFormType.LOGIN ? "Log in" : "Register"}
        </Heading>
        <Text colorScheme="red">{state.error}</Text>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            name="email"
            value={state.email}
            onChange={(e) =>
              dispatch({
                type: "field",
                field: "email",
                value: e.target.value,
              })
            }
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <InputGroup>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={state.password}
              onChange={({ target }) => {
                dispatch({
                  type: "field",
                  field: "password",
                  value: target.value,
                });
              }}
            />
            <InputRightElement
              width="4.75rem"
              children={
                <Button
                  height="1.75rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              }
            ></InputRightElement>
          </InputGroup>
        </FormControl>

        {authFormType === AuthFormType.REGISTER && (
          <FormControl>
            <FormLabel htmlFor="confirm-password">Confirm Password</FormLabel>
            <InputGroup>
              <Input
                id="confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                name="confirm-password"
                value={state.confirmPassword}
                onChange={({ target }) => {
                  dispatch({
                    type: "field",
                    field: "confirmPassword",
                    value: target.value,
                  });
                }}
              />
              <InputRightElement
                width="4.75rem"
                children={
                  <Button
                    height="1.75rem"
                    size="sm"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? "Hide" : "Show"}
                  </Button>
                }
              ></InputRightElement>
            </InputGroup>
          </FormControl>
        )}
        <div>
          <Button
            type="submit"
            colorScheme="blue"
            disabled={state.loading || isInvalid}
          >
            Submit
          </Button>
        </div>

        <Link
          color="blue.500"
          as={NavLink}
          to={
            authFormType === AuthFormType.LOGIN
              ? "/auth/register"
              : "/auth/login"
          }
        >
          {authFormType === AuthFormType.LOGIN
            ? "Need to make a new account? Register here"
            : "Already have an account? Log in here"}
        </Link>
      </Stack>
    </form>
  );
};

export default AuthForm;
