import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Container,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { initialValues, validationSchema, FormValues } from "./types";
import { YesNoBool } from "../../../common/types";
import { POST_APPLICATION } from "./gql";
import { urls } from "../../../constants";

const ApartmentApplication = () => {
  const [persistApplication] = useMutation<FormValues>(POST_APPLICATION);
  const [submitError, setSubmitError] = useState("");
  const history = useHistory();

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (
          { financials, currentAddress, ...values },
          { setSubmitting }
        ) => {
          try {
            const variables = {
              ...values,
              ...currentAddress,
              ...financials,
              dateOfBirth: new Date(values.dateOfBirth).toISOString(),
              applicationDate: new Date().toISOString(),
            };
            await persistApplication({ variables });
            history.push(urls.documents.submitted);
          } catch (err) {
            setSubmitError(err);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          isSubmitting,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack margin="2rem" spacing={7}>
              <Heading>Apartment Application</Heading>
              <Text>
                Hello! Thank for your interest in our apartments. We're excited
                to get to know you a little better.
              </Text>
              <Text>
                We'll first ask a few quick questions to determine if our
                apartments are a good fit for you.
              </Text>
              <Text>
                If you encounter any issues with submitting information in this
                form, please contact us via email{" "}
                <Link href="mailto:dragonrealty.xyz@gmail.com" color="blue">
                  here
                </Link>
              </Text>
              <Text>{JSON.stringify(errors, null, 2)}</Text>
              <Text>{JSON.stringify(touched, null, 2)}</Text>

              {submitError && <Text color="red">Error: {submitError}</Text>}
              <FormControl
                isInvalid={!!(touched.firstName && errors.firstName)}
                isRequired
              >
                <FormLabel>First name</FormLabel>
                <Field name="firstName" as={Input} />
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!(touched.lastName && errors.lastName)}
                isRequired
              >
                <FormLabel>Last name</FormLabel>
                <Field name="lastName" as={Input} />
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!(touched.dateOfBirth && errors.dateOfBirth)}
                isRequired
              >
                <FormLabel>Date of birth</FormLabel>
                <Input type="date" {...getFieldProps("dateOfBirth")} />
                <FormErrorMessage>{errors.dateOfBirth}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!(touched.phoneNumber && errors.phoneNumber)}
                isRequired
              >
                <FormLabel>Phone number</FormLabel>
                <Field name="phoneNumber" as={Input} />
                <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!(touched.email && errors.email)}
                isRequired
              >
                <FormLabel>Email address</FormLabel>
                <Field name="email" as={Input} />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.currentAddress?.street &&
                    errors.currentAddress?.street
                  )
                }
                isRequired
              >
                <FormLabel>Street address</FormLabel>
                <Field name="currentAddress.street" as={Input} />
                <FormErrorMessage>
                  {errors.currentAddress?.street}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.currentAddress?.apartmentNumber &&
                    errors.currentAddress?.apartmentNumber
                  )
                }
              >
                <FormLabel>Apartment number</FormLabel>
                <Field name="currentAddress.apartmentNumber" as={Input} />
                <FormErrorMessage>
                  {errors.currentAddress?.apartmentNumber}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.currentAddress?.town && errors.currentAddress?.town
                  )
                }
                isRequired
              >
                <FormLabel>Town</FormLabel>
                <Field name="currentAddress.town" as={Input} />
                <FormErrorMessage>
                  {errors.currentAddress?.town}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.currentAddress?.state &&
                    errors.currentAddress?.state
                  )
                }
                isRequired
              >
                <FormLabel>State</FormLabel>
                <Field name="currentAddress.state" as={Input} />
                <FormErrorMessage>
                  {errors.currentAddress?.state}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(touched.currentAddress?.zip && errors.currentAddress?.zip)
                }
                isRequired
              >
                <FormLabel>Zip code</FormLabel>
                <Field name="currentAddress.zip" as={Input} />
                <FormErrorMessage>
                  {errors.currentAddress?.zip}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!(errors.numOccupants && touched.numOccupants)}
                isRequired
              >
                <FormLabel>
                  How many people will you have living in the rented space?
                </FormLabel>
                <Input type="number" {...getFieldProps("numOccupants")} />
                <FormErrorMessage>{errors.numOccupants}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!(touched.hasPets && errors.hasPets)}
                isRequired
              >
                <FormLabel>Do you have pets?</FormLabel>
                <RadioGroup
                  {...getFieldProps("hasPets")}
                  onChange={(nextValue) => setFieldValue("hasPets", nextValue)}
                >
                  <HStack spacing="24px">
                    <Radio value={YesNoBool.YES}>Yes</Radio>
                    <Radio value={YesNoBool.NO}>No</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{errors.hasPets}</FormErrorMessage>
                <FormHelperText>
                  We only allow certain kinds of pets. Please indicate below
                  what kind of pet you have, if any.
                </FormHelperText>
              </FormControl>

              <FormControl
                isDisabled={values.hasPets === YesNoBool.NO}
                isRequired={values.hasPets === YesNoBool.YES}
                isInvalid={
                  !!(touched.petsExplanation && errors.petsExplanation)
                }
              >
                <FormLabel>If you have a pet, then please explain:</FormLabel>
                <Field name="petsExplanation" as={Textarea} />
                <FormErrorMessage>{errors.petsExplanation}</FormErrorMessage>
                <FormHelperText>
                  Tell us what kind of pet you have so we can make our best
                  judgement on whether to allow it in the building
                </FormHelperText>
              </FormControl>

              <FormControl
                isInvalid={!!(touched.needsParking && errors.needsParking)}
                isRequired
              >
                <FormLabel>Do you need a parking space?</FormLabel>
                <RadioGroup
                  {...getFieldProps("needsParking")}
                  onChange={(nextValue) =>
                    setFieldValue("needsParking", nextValue)
                  }
                >
                  <HStack spacing="24px">
                    <Radio value={YesNoBool.YES}>Yes</Radio>
                    <Radio value={YesNoBool.NO}>No</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{errors.needsParking}</FormErrorMessage>
                <FormHelperText>
                  Parking is limited, so availability is not guaranteed
                </FormHelperText>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(touched.reasonForMoving && errors.reasonForMoving)
                }
              >
                <FormLabel>Reason for moving</FormLabel>
                <Field name="reasonForMoving" as={Textarea} />
                <FormErrorMessage>{errors.reasonForMoving}</FormErrorMessage>
                <FormHelperText>
                  Provide a short description for why you are trying to move
                </FormHelperText>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.financials?.yearlyIncome &&
                    errors.financials?.yearlyIncome
                  )
                }
                isRequired
              >
                <FormLabel>Yearly Income (USD)</FormLabel>
                <NumberInput min={0} step={1000}>
                  <NumberInputField
                    {...getFieldProps("financials.yearlyIncome")}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>
                  {errors.financials?.yearlyIncome}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.financials?.occupationTitle &&
                    errors.financials?.occupationTitle
                  )
                }
                isRequired
              >
                <FormLabel>Occupation / Job title</FormLabel>
                <Input {...getFieldProps("financials.occupationTitle")} />
                <FormErrorMessage>
                  {errors.financials?.occupationTitle}
                </FormErrorMessage>
                <FormHelperText>
                  Briefly describe what you do for a living
                </FormHelperText>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(touched.financials?.company && errors.financials?.company)
                }
                isRequired
              >
                <FormLabel>Employer</FormLabel>
                <Input {...getFieldProps("financials.company")} />
                <FormErrorMessage>
                  {errors.financials?.company}
                </FormErrorMessage>
                <FormHelperText>
                  Company name, Self-employed, Student, or if other, then
                  explain
                </FormHelperText>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(
                    touched.financials?.yearsOfEmployment &&
                    errors.financials?.yearsOfEmployment
                  )
                }
                isRequired
              >
                <FormLabel>Length of employment (in years)</FormLabel>
                <Input
                  type="number"
                  {...getFieldProps("financials.yearsOfEmployment")}
                />
                <FormErrorMessage>
                  {errors.financials?.yearsOfEmployment}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !!(touched.consentSignature && errors.consentSignature)
                }
                isRequired
              >
                <Heading marginBottom="5">Consent and Acknowledgement</Heading>
                <Container marginBottom="5">
                  I hereby certify that I am at least 18 years of age. Applicant
                  represents that all information given on this application is
                  true and correct. Applicant hereby authorizes verification of
                  all references and facts, including but not limited to current
                  and previous landlords, employers, and personal references.
                  Applicant hereby authorizes owner/agent to obtain any and all
                  Unlawful Detainer, Credit Reports, Telechecks, and/or Criminal
                  Background Reports. Applicant agrees to furnish additional
                  credit and/or personal references upon request. Applicant
                  understands that incomplete or incorrect information provided
                  in the application may cause a delay in processing which may
                  result in denial of tenancy. Applicant hereby waives any claim
                  and releases from liability any person providing or obtaining
                  said verification or additional information.
                </Container>

                <FormLabel>
                  **If you agree to the Consent and Acknowledgement agreement,
                  then type your name here
                </FormLabel>
                <Input
                  placeholder="Your name here"
                  {...getFieldProps("consentSignature")}
                />
                <FormErrorMessage>{errors.consentSignature}</FormErrorMessage>
              </FormControl>

              <div>
                <Button
                  colorScheme="blue"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ApartmentApplication;
