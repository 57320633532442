import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

import { getValidAccessToken } from "../realm";

const GQL_ENDPOINT =
  "https://realm.mongodb.com/api/client/v2.0/app/rentalmanager-aceti/graphql";

export const client = new ApolloClient({
  link: new HttpLink({
    uri: GQL_ENDPOINT,
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options = {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache(),
});
