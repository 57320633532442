import { gql } from "@apollo/client";

export const POST_APPLICATION = gql`
  mutation InsertApplication(
    $firstName: String!
    $lastName: String!
    $dateOfBirth: DateTime!
    $street: String!
    $apartmentNumber: String
    $town: String!
    $state: String!
    $zip: String!
    $phoneNumber: String!
    $email: String!
    $numOccupants: Int!
    $hasPets: Boolean!
    $petsExplanation: String
    $needsParking: Boolean!
    $yearlyIncome: Int!
    $occupationTitle: String!
    $company: String!
    $yearsOfEmployment: Int!
    $reasonForMoving: String!
    $consentSignature: String!
    $applicationDate: DateTime!
  ) {
    insertOneApplication(
      data: {
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $dateOfBirth
        currentAddress: {
          street: $street
          apartmentNumber: $apartmentNumber
          town: $town
          state: $state
          zip: $zip
        }
        phoneNumber: $phoneNumber
        email: $email
        numOccupants: $numOccupants
        hasPets: $hasPets
        petsExplanation: $petsExplanation
        needsParking: $needsParking
        financials: {
          yearlyIncome: $yearlyIncome
          occupationTitle: $occupationTitle
          company: $company
          yearsOfEmployment: $yearsOfEmployment
        }
        reasonForMoving: $reasonForMoving
        consentSignature: $consentSignature
        applicationDate: $applicationDate
      }
    ) {
      firstName
    }
  }
`;
