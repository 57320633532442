import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";

import { Person, getPersonRoleMapping } from "../../../types/people";

interface Props {
  people: Person[];
}

const PeopleTable = ({ people }: Props) => {
  // const [filteredPeople, setFilteredPeople] = useState(people);
  const [filteredPeople] = useState(people);

  // const onSearch = (search: Record<string, any>) => {
  //   let nextData;
  //   if (search) {
  //     const searchProps = Object.keys(search);

  //     nextData = people.filter(
  //       (person) =>
  //         !searchProps.some((key) => {
  //           const currValue: string = search[key];
  //           if (!currValue) return false;
  //           switch (key) {
  //             case "name":
  //               const name = `${person.firstName} ${person.lastName}`.toLowerCase();
  //               return name.indexOf(currValue.toLowerCase()) === -1;
  //             default:
  //               return false;
  //           }
  //         })
  //     );
  //   } else {
  //     nextData = people;
  //   }
  //   setFilteredPeople(nextData);
  // };

  return (
    <Table variant="simple">
      <TableCaption>People registered in the system</TableCaption>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Phone number</Th>
          <Th>Role</Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredPeople.map(({ firstName, lastName, contact, roles }) => (
          <Tr>
            <Td>{`${firstName} ${lastName}`}</Td>
            <Td>{contact.mobile}</Td>
            <Td>{getPersonRoleMapping(Math.max(...roles))}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default PeopleTable;
