// PersonRole will dictate what permissions they are allowed to have in the app
export enum PersonRole {
  NONE = 0, // lowest privilege
  TENANT, // power over an apartment
  SUPER, // power over a building
  MANAGER, // power over an area
  ADMIN, // highest privilege
}

const getEnumLength = (enumName: any): number => {
  let count = 0;
  for (let item in enumName) {
    if (isNaN(Number(item))) count++;
  }
  return count;
};

export const getPersonRoleMapping = (number: number): string | null => {
  if (number < 0 || number >= getEnumLength(PersonRole)) {
    return null;
  }
  return PersonRole[number];
};

// Person represents a person whether it be a tenant, manager, etc.
export interface Person {
  firstName: string;
  lastName: string;
  name?: string;
  contact: {
    mobile: string;
    email: string;
  };
  roles: number[];
}

export interface Tenant extends Person {}
