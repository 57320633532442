import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AuthForm from "../../components/auth/AuthForm";
import { AuthFormType } from "./types";

const AuthRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/register`}>
        <AuthForm authFormType={AuthFormType.REGISTER} />
      </Route>
      <Route path={`${path}/login`}>
        <AuthForm authFormType={AuthFormType.LOGIN} />
      </Route>
    </Switch>
  );
};

export default AuthRouter;
