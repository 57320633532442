import * as Yup from "yup";

import { DefaultFormValidationLimits } from "../../../common/types";

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(DefaultFormValidationLimits.STRING_MAX)
    .required("Please supply your first name"),
  lastName: Yup.string()
    .max(DefaultFormValidationLimits.STRING_MAX)
    .required("Please supply your last name"),
  dateOfBirth: Yup.date().required("Please supply your date of birth"),
  currentAddress: Yup.object({
    street: Yup.string()
      .max(DefaultFormValidationLimits.STRING_MAX)
      .required("Please supply your street address"),
    apartmentNumber: Yup.string().max(DefaultFormValidationLimits.STRING_MAX),
    town: Yup.string()
      .max(DefaultFormValidationLimits.STRING_MAX)
      .required("Please supply your town"),
    state: Yup.string()
      .length(
        2,
        "States must be abbreviated in their 2-character format. (New Jersey = NJ)"
      )
      .required(),
    zip: Yup.string()
      .length(5, "Zip codes must be 5 digits")
      .required("Please supply your zip code"),
  }),
  phoneNumber: Yup.string().min(10).required("Please supply your phone number"),
  email: Yup.string()
    .max(DefaultFormValidationLimits.STRING_MAX)
    .email("Invalid email address")
    .required("Please supply your email address"),
  numOccupants: Yup.number()
    .min(1, "There needs to be at least one person living at the apartment")
    .required("Please supply the number of intended occupants"),
  hasPets: Yup.string()
    .oneOf(
      ["yes", "no"],
      "Please answer whether you have pets with a yes/no answer"
    )
    .required(),
  petsExplanation: Yup.string().max(500, "500 character limit"),
  needsParking: Yup.string()
    .oneOf(
      ["yes", "no"],
      "Please answer whether you have pets with a yes/no answer"
    )
    .required(),
  financials: Yup.object({
    yearlyIncome: Yup.number()
      .min(0, "Cannot have a yearly income below $0")
      .required("Please supply your estimated yearly salary"),
    occupationTitle: Yup.string()
      .max(DefaultFormValidationLimits.STRING_MAX)
      .required(
        "Please supply your occupation or a description of what you do for a living"
      ),
    company: Yup.string()
      .max(DefaultFormValidationLimits.STRING_MAX)
      .required("Please supply your employer"),
    yearsOfEmployment: Yup.number()
      .min(
        0,
        "Please supply a positive number of years that you've worked at your company for"
      )
      .required("Please"),
  }),
  reasonForMoving: Yup.string().max(500, "500 character limit"),
  consentSignature: Yup.string().required(
    "Please confirm that you've acknowledged the above"
  ),
});

export type FormValues = Yup.InferType<typeof validationSchema>;

export const initialValues: FormValues = {
  firstName: "",
  lastName: "",
  dateOfBirth: new Date(),
  currentAddress: {
    street: "",
    apartmentNumber: "",
    town: "",
    state: "",
    zip: "",
  },
  phoneNumber: "",
  email: "",
  numOccupants: 0,
  hasPets: "yes",
  petsExplanation: "",
  needsParking: "no",
  financials: {
    yearlyIncome: 0,
    occupationTitle: "",
    company: "",
    yearsOfEmployment: 0,
  },
  reasonForMoving: "",
  consentSignature: "",
};
