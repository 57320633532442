import React from "react";
/* import { useQuery } from "@apollo/client"; */
// import { GET_APARTMENTS } from "../../../services/graphql/queries";

const Home = () => {
  // const { data, loading, error } = useQuery(GET_APARTMENTS);
  // if (loading) {
  //   return <p>Getting data...</p>;
  // } else if (error) {
  //   return <p>something bad happened: {JSON.stringify(error)}</p>;
  // }
  const data = {
    apartments: [
      {
        __typename: "Apartment",
        _id: "5fa6132a64470ff16d59b19b",
        name: "1A",
        address: {
          __typename: "ApartmentAddress",
          street: "730 Harrison Avenue",
          city: "Harrison",
          state: "NJ",
          zip: "07029",
        },
      },
    ],
  };

  return (
    <>
      <h1 className="text-3xl text-red-900">Home</h1>
      <p>{JSON.stringify(data)}</p>
    </>
  );
};

export default Home;
