import React from "react";
import { IconButton, Stack } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";

import { useHistory } from "react-router-dom";

const SideNav = () => {
  let history = useHistory();

  const goToUrl = (url: string) => {
    history.push(url);
  };

  return (
    <Stack maxWidth={300} alignItems="flex-start">
      <IconButton
        variant="outline"
        colorScheme="teal"
        aria-label="Send email"
        icon={<EmailIcon />}
        onClick={() => goToUrl("/app")}
      />
      <IconButton
        variant="outline"
        colorScheme="teal"
        aria-label="Send email"
        icon={<EmailIcon />}
        onClick={() => goToUrl("/app/showings")}
      />
      <IconButton
        variant="outline"
        colorScheme="teal"
        aria-label="Send email"
        icon={<EmailIcon />}
        onClick={() => goToUrl("/app/applications")}
      />
      <IconButton
        variant="outline"
        colorScheme="teal"
        aria-label="Send email"
        icon={<EmailIcon />}
        onClick={() => goToUrl("/app/people")}
      />
    </Stack>
  );
};

export default SideNav;
