import React from "react";

import PeopleTable from "../../../components/people/people-table";
import { PersonRole } from "../../../types/people";
// import { useQuery } from "@apollo/client";
// import { GET_TENANTS } from "../../../services/graphql/queries";
// import { Tenant } from "../../../types/tenants";

const People = () => {
  //   const { loading, error, data } = useQuery<{ tenants: Tenant[] }>(GET_TENANTS);
  //   if (loading) {
  //     return <p>loading data...</p>;
  //   } else if (error) {
  //     return <p>error occurred: {JSON.stringify(error)}</p>;
  //   }
  const data = {
    tenants: [
      {
        __typename: "Tenant",
        contact: {
          __typename: "TenantContact",
          email: "andrew.chen923@gmail.com",
          mobile: "9738835195",
        },
        firstName: "Andrew",
        lastName: "Chen",
        roles: [PersonRole.ADMIN],
      },
    ],
  };
  return (
    <div>
      <h1>People</h1>
      <PeopleTable people={data.tenants} />
    </div>
  );
};

export default People;
