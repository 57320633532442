import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// services
import { app, RealmAppContext } from "./services/realm";

// components
import Layout from "./components/layout/Layout";

// pages
import AuthRouter from "./pages/auth";
import AppRouter from "./pages/app";
import DocumentsRouter from "./pages/documents";
import ErrorPage from "./pages/ErrorPage";

import "./tailwind.css";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const chakraTheme = extendTheme({ colors });

function App() {
  return (
    <ChakraProvider theme={chakraTheme}>
      <RealmAppContext.Provider value={app}>
        <Router>
          <Switch>
            <Route path="/app">
              <Layout withHeadNav withSideNav>
                <AppRouter />
              </Layout>
            </Route>

            <Layout>
              <Switch>
                <Route path="/auth">
                  <AuthRouter />
                </Route>
                <Route path="/documents">
                  <DocumentsRouter />
                </Route>
                <Route path="*">
                  <ErrorPage />
                </Route>
              </Switch>
            </Layout>
          </Switch>
        </Router>
      </RealmAppContext.Provider>
    </ChakraProvider>
  );
}

export default App;
