import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ApplicationSubmittedPage from "./application-submitted/ApplicationSubmitted";

import TenancyApplication from "./tenancy-application/TenancyApplication";

const AuthRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/tenancy-application`}>
        <TenancyApplication />
      </Route>
      <Route path={`${path}/application-submitted`}>
        <ApplicationSubmittedPage />
      </Route>
    </Switch>
  );
};

export default AuthRouter;
