import React from "react";
import { Heading } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/react";

const ApplicationSubmittedPage = () => {
  return (
    <Center style={{ height: "100vh", background: "#E2E8F0" }}>
      <div
        style={{ background: "white", padding: "35px", borderRadius: "10px" }}
      >
        <Heading marginBottom="5">Thank you for your application!</Heading>
        <p>
          We will reach out as soon as possible if we're a good fit. We look
          forward to speaking again soon!
        </p>
      </div>
    </Center>
  );
};

export default ApplicationSubmittedPage;
